import * as React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ReferenceBlock from "../../components/template-partials/reference-block"
import FooterCrown from "../../components/template-partials/footer-crown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleRight,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons"

const faqData = [
  {
    question: "What is systemic mastocytosis (SM)?",
    answer: (
      <div>
        <p>
          SM is a type of mast cell disorder. It is a clonal mast cell neoplasm
          associated with the uncontrolled proliferation and activation of
          abnormal mast cells. The disease can cause debilitating symptoms,
          significantly impacting patients’ lives.
          <sup>1-3</sup>
        </p>
        <a rel="noreferrer" href="/about-sm/" className="link">
          <span>Read about SM</span>
        </a>
      </div>
    ),
  },
  {
    question: "What is indolent systemic mastocytosis (ISM)?",
    answer: (
      <div>
        <p>
          ISM is the largest subtype of systemic mastocytosis (SM), and like
          other subtypes of SM, the symptoms of ISM can be unpredictable and
          potentially severe.<sup>1-6</sup> Patients with SM not experiencing
          organ damage or cytopenias may have ISM.<sup>7</sup>
        </p>
        <a href="/recognizing-sm/living-with-sm/" className="link">
          <span>Understand the burden of ISM</span>
        </a>
      </div>
    ),
  },
  {
    question: "What are the symptoms of systemic mastocytosis (SM)?",
    answer: (
      <div>
        <p>
          Symptoms of SM present across a range of organ systems.<sup>1</sup>{" "}
          Symptoms may vary among patients with SM, with the most common
          symptoms involving skin lesions, diarrhea, and anaphylaxis.
          <sup>8</sup>
        </p>
        <a href="/recognizing-sm/symptoms" className="link">
          <span>Explore the symptoms of SM</span>
        </a>
      </div>
    ),
  },
  {
    question:
      "Can the symptoms of systemic mastocytosis (SM) be mistaken for other disorders?",
    answer: (
      <div>
        <p>
          Yes. Patients with SM may have a provisional diagnosis of mast cell
          activation syndrome (MCAS), mastocytosis in skin (MIS)/cutaneous
          mastocytosis (CM), or inflammatory bowel disease (IBD)/irritable bowel
          syndrome (IBS). SM is nonspecific and may present differently among
          patients.<sup>1-3</sup>
        </p>
        <a href="/recognizing-sm/related-conditions/" className="link">
          <span>See related disorders</span>
        </a>
      </div>
    ),
  },
  {
    question: "How can I diagnose systemic mastocytosis (SM) in my patients?",
    answer: (
      <div>
        <p>
          If SM is suspected based on{" "}
          <a href="/recognizing-sm/symptoms/" className="link no_arrow">
            <span>presentation of symptoms</span>
          </a>
          , screening tools can help lead to an SM diagnosis.<sup>7,8</sup>{" "}
          Testing serum tryptase levels and high-sensitivity KIT D816V in
          peripheral blood may increase suspicion of SM.<sup>7,8</sup> However,
          patients may present with normal tryptase levels and not all SM
          patients are positive for KIT D816V mutation.<sup>7,8</sup> A bone
          marrow biopsy may be required for diagnosis.<sup>7,8</sup>
        </p>
        <a href="/diagnosing-sm#diagnostic-criteria" className="link">
          <span>See the diagnostic criteria</span>
        </a>
      </div>
    ),
  },
  {
    question:
      "Does a KIT D816V high-sensitivity test confirm a diagnosis of systemic mastocytosis (SM)?",
    answer: (
      <div>
        <p className="mb-1">
          Patients who test positive for KIT D816V mutation have an increased
          likelihood of SM, and a KIT D816V mutation is the main driver of
          disease in ~95% of SM cases.<sup>9-11</sup>
        </p>
        <p className="mb-1">
          Diagnostic criteria for SM put forth by the World Health Organization
          (WHO) and International Consensus Classiﬁcation (ICC) list a positive
          KIT D816V mutation found in bone marrow, blood, or other
          extracutaneous organ as a minor criterion.<sup>7,12</sup>
        </p>
        <p>
          Consider using a high-sensitivity KIT D816V assay when testing for SM,
          as low-sensitivity assays may fail to detect the mutation.
          <sup>13</sup>
        </p>
        <a
          href="/kit-testing-commercial-lab-sheet.pdf"
          target="_blank"
          className="link"
        >
          <span>Find a lab</span>
        </a>
      </div>
    ),
  },
  {
    question:
      "How can I reach other providers who are willing to share their experience diagnosing and managing systemic mastocytosis (SM) in their practice?",
    answer: (
      <div>
        <p>
          SM is a rare disease that can pose unique challenges due to the
          limited information available. You can independently reach out to a
          fellow healthcare provider who has attested that they have experience
          treating patients living with SM through the SM Provider Peer
          Directory.
        </p>

        <a
          href="https://www.smdirectory.com/"
          target="_blank"
          rel="noreferrer"
          className="link link_sm modal-link-bp"
        >
          <span>See peer directory</span>
        </a>
      </div>
    ),
  },
  {
    question: "What if I have additional questions?",
    answer: (
      <div>
        <p>
          For Blueprint medical information requests, please reach out by email
          at{" "}
          <a href="mailto:medinfo@blueprintmedicines.com" className="underline">
            medinfo@blueprintmedicines.com
          </a>{" "}
          or by phone at{" "}
          <a href="tel:18882587768" className="underline">
            1&#8209;888&#8209;BLUPRNT
          </a>{" "}
          (1&#8209;888&#8209;258&#8209;7768).
        </p>
      </div>
    ),
  },
]

const FAQComponent = () => {
  const [expanded, setExpanded] = useState(faqData.map(() => false))
  const [allExpanded, setAllExpanded] = useState(false)

  const toggleQuestion = index => {
    const newExpanded = [...expanded]
    newExpanded[index] = !newExpanded[index]
    setExpanded(newExpanded)
  }

  const toggleAll = () => {
    const newAllExpanded = !allExpanded
    setAllExpanded(newAllExpanded)
    setExpanded(faqData.map(() => newAllExpanded))
  }
  return (
    <div>
      <button onClick={toggleAll} className="expand_collapse_all_btn purple">
        {allExpanded ? (
          <>
            <FontAwesomeIcon icon={faMinus} />
            <span>Collapse all questions</span>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faPlus} />
            <span>Expand all questions</span>
          </>
        )}
      </button>
      {faqData.map((item, index) => (
        <div key={index} className="faqQuestion purple">
          <button
            onClick={() => toggleQuestion(index)}
            style={{ marginRight: "10px" }}
          >
            <strong>{item.question}</strong>
            {expanded[index] ? (
              <>
                <FontAwesomeIcon icon={faMinus} />
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faPlus} />
              </>
            )}
          </button>
          {expanded[index] && <div className="faqAnswer">{item.answer}</div>}
        </div>
      ))}
    </div>
  )
}

const FAQPage = () => (
  <Layout route="sm-resources/faqs">
    <Seo
      page="faqs"
      title="SM Frequently Asked Questions"
      description="Learn answers to frequently asked questions about recognizing and diagnosing systemic
mastocytosis (SM), including disease signs and symptoms, testing, and disease subtypes."
    />
    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-12">
        <h1 className="color--purple text--watch-quinn">
          Frequently asked questions
        </h1>
        <p className="h2 bold mt-3 purple">
          Here are some frequently asked questions you may have about
          recognizing and diagnosing systemic mastocytosis (SM)
        </p>
      </div>
    </div>

    <div className="row lg_mt-2 mt-1 align-center medium-align-left faq_component">
      <div className="columns small-12 large-12">
        <FAQComponent />
      </div>
    </div>

    <div className="row lg_mt-7 mt-7 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns small-12 medium-expand text-right">
        <p className="h4 color--purple">
          See downloadable resources <br className="show-for-large" /> for your
          practice
        </p>
        <Link
          className="btn btn--outline btn--icon uppercase mt-1"
          to="/sm-resources/downloads/"
        >
          DOWNLOAD BROCHURES AND MORE{" "}
          <FontAwesomeIcon className="" icon={faAngleRight} />
        </Link>
      </div>
    </div>
    <FooterCrown type="home" />
    <ReferenceBlock>
      <ol className="color--purple">
        <li>
          Pardanani A. <em>Am J Hematol</em>. 2023;98(7):1097-1116.
        </li>
        <li>
          Theoharides TC et al. <em>N Engl J Med</em>. 2015;373(2):163-172.
        </li>
        <li>
          Gülen T et al. <em>J Intern Med</em>. 2016;279(3):211-228.
        </li>
        <li>
          Ungerstedt J et al. <em>Cancers</em>. 2022;14(16):3942.
        </li>
        <li>
          Sperr WR et al. <em>Lancet Haematol</em>. 2019;6(12):e638-e649.
        </li>
        <li>
          Cohen SS et al. <em>Br J Haematol</em>. 2014;166(4):521-528.
        </li>
        <li>
          WHO Classification of Tumours Editorial Board. Haematolymphoid tumours
          [Internet]. Lyon (France): International Agency for Research on
          Cancer; 2024 [cited April 24, 2024]. (
          <em>WHO Classification of Tumours Series</em>, 5th ed.; vol. 11).
          Available from: https://tumourclassification.iarc.who.int/chapters/63
        </li>
        <li>
          Shomali W, Gotlib J. <em>Hematology</em>. 2018;2018(1):127-136.
        </li>
        <li>
          Garcia-Montero AC et al. <em>Blood</em>. 2006;108(7):2366-2372.
        </li>
        <li>
          Evans EK et al. <em>Sci Transl Med</em>. 2017;9(414):eaao1690.
        </li>
        <li>
          Kristensen T et al. <em>Am J Hematol</em>. 2014;89(5):493-498.
        </li>
        <li>
          Arber DA et al. <em>Blood</em>. 2022;140(11):1200-1228.
        </li>
        <li>
          Jara-Acevedo M et al. <em>Mod Pathol</em>. 2015;28(8):1138-1149.
        </li>
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default FAQPage
